// const Raven = require("../../external/raven-js/js/src/singleton");
import RavenConstructor from '../../external/raven-js/js/src/raven';
import RavenConsole from '../../external/raven-js/js/plugins/console';
// const _Raven = window.Raven;
const Raven = new RavenConstructor();

/*Raven.noConflict = function () {
    window.Raven = _Raven;
    return Raven;
};

Raven.afterLoad();*/


const raven = {
    ignoreErrors: [
        // Random plugins/extensions
        "top.GLOBALS",
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        "originalCreateNotification",
        "canvas.contentDocument",
        "MyApp_RemoveAllHighlights",
        "http://tt.epicplay.com",
        "Can\'t find variable: ZiteReader",
        "jigsaw is not defined",
        "ComboSearch is not defined",
        "http://loading.retry.widdit.com/",
        "atomicFindClose",
        // Facebook borked
        "fb_xd_fragment",
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        "bmi_SafeAddOnload",
        "EBCallBackMessageReceived",
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        "conduitPage",
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        "Script error."
    ],
    ignoreUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Statistics
        /mc\.yandex\.ru/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ]
};

module.exports = {
    Raven: Raven,

    init: function (url, revision) {
        "use strict";

        const enableRaven = (NODE_ENV === "prod");

        if (enableRaven) {
            Raven.config(url, {
                release: revision,
                ignoreErrors: raven.ignoreErrors,
                ignoreUrls: raven.ignoreUrls,
                environment: 'production',
                /*autoBreadcrumbs: {
                    'xhr': false,      // XMLHttpRequest
                    'console': false,  // console logging
                    'dom': true,       // DOM interactions, i.e. clicks/typing
                    'location': false, // url changes, including pushState/popState
                    'sentry': true     // sentry events
                },*/
                /*autoBreadcrumbs: {
                    'xhr': true,      // XMLHttpRequest
                    'console': true,  // console logging
                    'dom': true,       // DOM interactions, i.e. clicks/typing
                    'location': true, // url changes, including pushState/popState
                    'sentry': true     // sentry events
                },*/
                /*                plugin
                /*                plugins: [
                                    require('../../external/raven-js/js/plugins/console')
                                ],*/
                tags: {
                    git_commit: revision
                }
            });
            Raven.addPlugin(RavenConsole, console, {levels: ['error']});
            Raven.install();

            console.log("Raven initialized");

            this.addAjaxErrorHandling();

        } else {
            console.log("Raven not initialized");
        }
        return Raven;
    },

    call: function (func) {
        "use strict";

        try {
            func();
        } catch (e) {
            this.markPageError(e);
            if (typeof Raven !== "undefined" && Raven.isSetup()) {
                Raven.captureException(e);
            }
            console.error(e);
        }
    },

    addAjaxErrorHandling: function () {
        "use strict";

        if (typeof jQuery != "undefined") {
            const _this = this;
            $(document).ajaxError(function (event, jqXHR, ajaxSettings, thrownError) {
                const message = thrownError || jqXHR.statusText;
                _this.markPageError(message);
                if (typeof Raven != "undefined" && Raven.isSetup()) {
                    Raven.captureMessage(message, {
                        extra: {
                            type: ajaxSettings.type,
                            url: ajaxSettings.url,
                            data: ajaxSettings.data,
                            status: jqXHR.status,
                            error: message,
                            response: jqXHR.responseText.substring(0, 100)
                        }
                    });
                }
            });
        }
    },

    markPageError: function (message) {
        document.body.setAttribute("JSError", message)
    }
};